import { EticketPortalMixer } from '@linktivity/eticketportal';
import { message } from '@linktivity/link-ui';
import {
  ClientError,
  ServerError,
  ConnectionError,
  RequestError,
  EticketError,
  EticketOTPError
} from '../error';

const INTERNAL_SERVER_ERROR = 500;
const BASE_PATH = import.meta.env.VITE_API_PORTAL_HOST;

interface ApiErrorResponse {
  id?: string;
  metadata?: {
    REASON?: string;
  };
}

const handleApiError = async (
  context: EticketPortalMixer.ResponseContext
): Promise<Response> => {
  const { response } = context;
  if (response.ok) return response;

  let apiError: Error;

  if (response.status < INTERNAL_SERVER_ERROR) {
    const data: ApiErrorResponse = await response.json();
    if (data?.id?.startsWith('OTP')) {
      apiError = new EticketOTPError(data.metadata?.REASON || '', data.id);
    } else if (data?.id) {
      apiError = new EticketError(`${data.id} ${data.metadata?.REASON || ''}`);
    } else {
      apiError = new ClientError();
    }
  } else {
    apiError = new ServerError();
  }

  if (!response.ok && !response.status) {
    apiError = new ConnectionError();
  }

  if (apiError instanceof EticketOTPError) {
    apiError.show();
  } else {
    apiError = apiError || new RequestError();
    message.error(apiError.message, 10);
  }

  return new Response(
    JSON.stringify({
      apiError
    })
  );
};

const config = new EticketPortalMixer.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async (
        context: EticketPortalMixer.RequestContext
      ): Promise<void> => {
        context.init.headers = new Headers({
          'Content-Type': 'application/json'
        });
      },
      post: handleApiError
    }
  ]
});

const eticketPortal = new EticketPortalMixer.EticketPortalMixerApi(config);

export default eticketPortal;
