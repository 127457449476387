import { EticketPortalMixer } from '@linktivity/eticketportal';

const Language = EticketPortalMixer.Language;
type Language = EticketPortalMixer.Language;

const Country = EticketPortalMixer.Country;
type Country = EticketPortalMixer.Country;

const SubStatusId = EticketPortalMixer.SubStatusId;
type SubStatusId = EticketPortalMixer.SubStatusId;

const TicketTypeId = EticketPortalMixer.TicketTypeId;
type TicketTypeId = EticketPortalMixer.TicketTypeId;

const CodeType = EticketPortalMixer.CodeType;
type CodeType = EticketPortalMixer.CodeType;

const SupplierOperation = EticketPortalMixer.SupplierOperation;
type SupplierOperation = EticketPortalMixer.SupplierOperation;

const StatusId = EticketPortalMixer.EticketportalmixerStatusId;
type StatusId = EticketPortalMixer.EticketportalmixerStatusId;

const CheckInCodeId = EticketPortalMixer.CheckInCodeId;
const CheckInCodeValidationId = EticketPortalMixer.CheckInCodeValidationId;

export {
  Language,
  Country,
  SubStatusId,
  TicketTypeId,
  CodeType,
  SupplierOperation,
  StatusId,
  CheckInCodeId,
  CheckInCodeValidationId
};
