import sha1 from 'crypto-js/sha1';

const wechatUA = 'MicroMessenger';
const alipayUA = 'AlipayClient';

export const isWechat = new RegExp(wechatUA, 'i').test(navigator.userAgent);
export const isAlipay = new RegExp(alipayUA, 'i').test(navigator.userAgent);

const ua = (() => {
  if (isWechat) {
    return wechatUA;
  } else if (isAlipay) {
    return alipayUA;
  } else {
    return navigator.userAgent;
  }
})();

export const ud = sha1(ua).toString();
