export default {
  common: {
    colon: ': ',
    cancel: '取消',
    confirm: '確認',
    backToTickets: '回到票券列表',
    backToTicketDetails: '回到票券詳情',
    day: '天',
    hour: '小时',
    min: '分',
    second: '秒'
  },
  views: {
    voucher: {
      bookingId: '訂單編號',
      bookingDetails: '訂單詳情',
      printable: '列印用憑證',
      participation: '集合或兌換地點說明',
      inclusion: '費用包含',
      restrictions: '參加限制',
      requiredAttire: '須攜帶的物品/服裝',
      requirements: '參加時必需的其他事項',
      other: '備註',
      localContact: '當地聯絡方式',
      cancellation: '取消政策',
      clickUrl: '請點擊該連結使用票券'
    },
    booking: {
      details: '訂單詳情',
      bookingId: 'Booking {{id}}',
      history: '使用記錄',
      bookedDate: '訂購日',
      participationLocations: '參加地點',
      participationLocationKind: {
        MAP_OF_MEETING_POINT: '地點說明',
        NOTE_OF_LOCATION_AND_DIRECTION: '地點說明與前往方式',
        LANDMARKS_TO_MEETING_POINT: '周邊參考路標',
        PICK_UP_CAR: '迎接車輛',
        PICK_UP_GUIDE: '迎接人員',
        ITINERARY: '行程',
        PARKING: '停車處'
      },
      ticketType: {
        CODE_CPM: '展示二維碼',
        CODE_MPM: '掃描二維碼',
        SELF_REDEMPTION: '展示票券',
        SIMPLE_VOUCHER: '展示票券'
      },
      inclusion: '費用包含',
      restrictions: '參加限制',
      requirements: '參加之前和期間的必要事項',
      attireItems: '须携带的物品/服装',
      otherInfo: '備註/其他',
      cancellationPolicy: '取消政策'
    },
    otp: {
      title: '動態密碼',
      tips1:
        '您的訪問環境已變更。 \n 為確保安全，系統將向您發送動態密碼以進行驗證。',
      tips2: '請登錄您的E-mail，以便接收動態密碼。',
      inputOTPCode: '請輸入郵件中所記載的動態密碼。',
      sendCode: '已將動態密碼發送至{{email}}，請確認。',
      invalidEmail: '此E-mail無效。',
      inputEmail: '請輸入您的E-mail。',
      inputPassword: '請輸入動態密碼。',
      sendOTP: '發送動態密碼'
    },
    ticket: {
      status: {
        INVALID: '無効',
        AWAITING: '使用可能期間前',
        UNUSED: '使用前',
        USABLE: '可使用',
        IN_USE: '使用中',
        USED: '已使用',
        EXPIRED: '已過期',
        DISTRIBUTED: '已分發',
        RETURNED: '已返還'
      },
      subStatus: {
        ENTERED: '已進站',
        EXITED: '已出站',
        INITIAL_STATION_CANCELLED: '撤銷進站'
      },
      nowTime: '當前時間',
      startTime: '開始時間',
      passed: '已過',
      QRCodeExpirationRemainingTime: 'QR Code剩餘有效期',
      screenShotTips: '僅持QR code 的截圖恕無法進站。',
      showScreenToStaff: '請向工作人員出示此畫面。',
      instruction: '使用說明',
      usedAt: '使用日',
      availablePeriod: '使用可能期間',
      bookingId: '訂單編號',
      startUsing: '使用',
      startUsingTips: '確認開始使用電子票嗎？',
      distributeTicket: '分發',
      distributeTicketTips:
        '若無法使用分發票券按鈕複製連結，請直接複製以上連結並發送給同行者。',
      reDistributeTicket: '重新分發',
      reDistributeTips: '※僅同行者票券處於『未使用』狀態時可重新分發。',
      reDistributeTips2: '※重新分發後，原連結即失效。',
      copyLinkSuccess: '已複製連結',
      use: '使用',
      confirmUse: '確認使用內容',
      useTicket: '使用<span>{{count}}</span>張',
      issueTicketTips: '請在工作人員面前使用',
      issueTicketNotes: '※使用後不可取消',
      undo: '取消使用',
      redeem: '使用開始',
      thanks: '謝謝您的使用',
      second: '{{count}}秒',
      expirationDate: '有効期限',
      supplier: '供應商',
      participatorName: '參加者姓名',
      validPeriod: '使用後有效期',
      fixedTime: '{{day}} 天 (最終日到當地時間{{time}}为止)',
      enableCamera: '請允許本服務使用相機權限。'
    },
    history: {
      title: '使用記錄'
    },
    apiErrors: {
      baseError: '發生錯誤。',
      clientError: '發送API請求時發生客戶端錯誤。',
      serverError: '伺服器無法完成該請求。',
      connectionError: '連接伺服器失敗，煩請檢查您的網路設定並重新嘗試。',
      requestError: 'API請求發生錯誤。'
    }
  }
};
