export default {
  common: {
    colon: ': ',
    cancel: '取消',
    confirm: '确认',
    backToTickets: '返回票券列表',
    backToTicketDetails: '返回票券明细',
    day: '天',
    hour: '小时',
    min: '分',
    second: '秒'
  },
  views: {
    voucher: {
      bookingId: '订单编号',
      bookingDetails: '订单详情',
      printable: '打印用凭证',
      participation: '集合或兑换地点说明',
      inclusion: '费用包含',
      restrictions: '参加制限',
      requiredAttire: '须携带的物品/服装',
      requirements: '参加时必需的其他事项',
      other: '备注',
      localContact: '当地联络方式',
      cancellation: '取消政策',
      clickUrl: '请点击该链接使用票券'
    },
    booking: {
      details: '订单详情',
      bookingId: 'Booking {{id}}',
      history: '使用记录',
      bookedDate: '订购日',
      participationLocations: '参加地点',
      participationLocationKind: {
        MAP_OF_MEETING_POINT: '地点说明',
        NOTE_OF_LOCATION_AND_DIRECTION: '地点说明与前往方式',
        LANDMARKS_TO_MEETING_POINT: '周边参考路标',
        PICK_UP_CAR: '迎接车辆',
        PICK_UP_GUIDE: '迎接人员',
        ITINERARY: '行程',
        PARKING: '关于停车'
      },
      ticketType: {
        CODE_CPM: '展示二维码',
        CODE_MPM: '扫描二维码',
        SELF_REDEMPTION: '展示票券',
        SIMPLE_VOUCHER: '展示票券'
      },
      inclusion: '费用包含',
      restrictions: '参加限制',
      requirements: '参加之前和期间的必要事项',
      attireItems: '须携带的物品/服装',
      otherInfo: '备注/其他',
      cancellationPolicy: '取消政策'
    },
    otp: {
      title: '一次性密码',
      tips1:
        '您的访问环境已更改。 \n 为确保安全，系统将向您发送一次性密码以进行验证。',
      tips2: '请注册您的电子邮箱地址，以便接收一次性密码。',
      inputOTPCode: '请输入邮件中所记载的一次性密码。',
      sendCode: '已将一次性密码发送至{{email}}，请确认。',
      invalidEmail: '此电子邮箱无效。',
      inputEmail: '请输入您的电子邮箱。',
      inputPassword: '请输入一次性密码。',
      sendOTP: '发送一次性密码'
    },
    ticket: {
      status: {
        INVALID: '无效',
        AWAITING: '使用可能期間前',
        UNUSED: '使用前',
        USABLE: '可使用',
        IN_USE: '使用中',
        USED: '已使用',
        EXPIRED: '已过期',
        DISTRIBUTED: '已分发',
        RETURNED: '已返还'
      },
      subStatus: {
        ENTERED: '已进站',
        EXITED: '已出站',
        INITIAL_STATION_CANCELLED: '撤销进站'
      },
      nowTime: '当前时间',
      startTime: '开始时间',
      passed: '已过',
      QRCodeExpirationRemainingTime: '二维码剩余有效期',
      screenShotTips: '仅持二维码的截图恕无法进站。',
      showScreenToStaff: '请向工作人员出示此画面。',
      instruction: '使用说明',
      usedAt: '使用日',
      availablePeriod: '使用可能期间',
      bookingId: '订单编号',
      startUsing: '使用',
      startUsingTips: '确认开始使用电子票吗？',
      distributeTicket: '分发',
      distributeTicketTips:
        '若无法使用分发票券按钮复制链接，请直接复制以上链接并发送给同行者。',
      reDistributeTicket: '重新分发',
      reDistributeTips: '※仅同行者票券处于“未使用”状态时可重新分发。',
      reDistributeTips2: '※重新分发后，原链接即失效。',
      copyLinkSuccess: '已复制链接',
      use: '使用',
      confirmUse: '确认使用内容',
      useTicket: '使用<span>{{count}}</span>张',
      issueTicketTips: '请在工作人员面前使用',
      issueTicketNotes: '※使用后不可取消',
      undo: '取消使用',
      redeem: '使用开始',
      thanks: '谢谢您的使用',
      second: '{{count}}秒',
      expirationDate: '有効期限',
      supplier: '供应商',
      participatorName: '参加者姓名',
      validPeriod: '使用后有效期',
      fixedTime: '{{day}} 天 (最终日到当地时间{{time}}为止)',
      enableCamera: '请允许本服务使用相机权限。'
    },
    history: {
      title: '使用记录'
    },
    apiErrors: {
      baseError: '检测到有错误发生。',
      clientError: 'API 请求存在错误。',
      serverError: '服务器无法完成该请求。',
      connectionError: '与服务器的连接失败。请检查网络设置后重试。',
      requestError: 'API 请求存在错误。'
    }
  }
};
