import { action, observable, toJS, computed } from 'mobx';
import { eticketPortal } from '@link/portal/services';
import {
  GetTicketResponse,
  GetTicketRequest,
  OperationHistory
} from '@link/portal/services/types';

export class TicketData {
  @observable accessor loading = false;
  @observable accessor TicketData: GetTicketResponse = {};
  @observable accessor OperationHistories: OperationHistory[] = [];
  @observable accessor unitIndex = 0;

  @action
  getTicketData = async (body: GetTicketRequest) => {
    try {
      this.setLoading(true);
      const TicketData = await eticketPortal.getTicket({ body });
      if (TicketData) {
        this.setTicketData(TicketData);
      }
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getOperationHistories = async (body: GetTicketRequest) => {
    try {
      this.setLoading(true);
      const { operationHistories } = await eticketPortal.getTicketHistories({
        body
      });
      if (operationHistories?.length) {
        this.setOperationHistories(operationHistories);
      }
    } finally {
      this.setLoading(false);
    }
  };

  @action
  startUsing = async (body: GetTicketRequest) => {
    await eticketPortal.startUsingTicket({ body });
  };

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setTicketData(detail: GetTicketResponse) {
    this.TicketData = detail;
  }

  @action
  setOperationHistories(histories: OperationHistory[]) {
    this.OperationHistories = histories;
  }

  @action
  setUnitIndex(index: number) {
    this.unitIndex = index;
  }

  @computed
  get ticket() {
    return toJS(this.TicketData.ticket) || {};
  }

  @computed
  get globalUiSetting() {
    return toJS(this.ticket.globalUiSetting) || {};
  }
}

export default new TicketData();
